<template>
    <div class="demo-spin-article">
        <div class="row mb-3">
            <label class="form-label col-label required">{{
                $t('fixedAllowance.itemAllowance')
            }}</label>
            <div>
                <Select
                    v-model="allowance_id"
                    :class="{
                        'is-invalid': errors.has('allowance_id')
                    }"
                >
                    <Option
                        v-for="(
                            payrollItemAllowance, index
                        ) in payrollItemAllowances"
                        :value="payrollItemAllowance.allowance_id"
                        :key="index"
                        :label="payrollItemAllowance.allowance_item"
                        >{{ payrollItemAllowance.allowance_item }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('allowance_id')">
                    {{ errors.first('allowance_id') }}
                </div>
            </div>
        </div>
        <div>
            <div class="col-md-12 mb-3">
                <label
                    for="file"
                    class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer required"
                    >Browse File</label
                >
                <input
                    type="file"
                    ref="file"
                    id="file"
                    class="tw-hidden"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFileChange"
                    :class="{
                        'is-invalid': errors.has('file')
                    }"
                />
                <div class="invalid-feedback" v-if="errors.has('file')">
                    {{ errors.first('file') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                {{ display_name }}
            </div>
        </div>
        <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
            <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                {{ $t('cancel') }}
            </ts-button>
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting"
                @click.prevent="onSave"
            >
                {{ $t('import') }}
            </ts-button>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions } from 'vuex'

export default {
    data () {
        return {
            errors: new Errors(),
            display_name: null,
            loading: false,
            waiting: false,
            allowance_id: null
        }
    },
    computed: {
        payrollItemAllowances () {
            return this.$store.state.payroll.fixedAllowance
                .payrollItemAllowances
        }
    },
    methods: {
        ...mapActions('payroll/fixedAllowance', ['getPayrollItemAllowance']),
        fetchResource () {
            this.loading = true
            this.getPayrollItemAllowance()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('allowance_id', this.allowance_id)
            this.$store
                .dispatch('payroll/fixedAllowance/importExcel', formData)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onFileChange (e) {
            this.display_name = null
            this.file = e.target.files[0]
            this.display_name = this.file ? this.file.name : null
        },
        clearInput () {
            this.errors = new Errors()
            this.allowance_id = null
            this.display_name = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'FIXED ALLOWANCE',
                desc: not.text
            })
        }
    }
}
</script>
